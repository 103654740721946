
import { Options, Vue } from 'vue-class-component';
import BaseNavigation from '@/components/BaseNavigation.vue';
import BaseField from '@/components/BaseField.vue';
import BaseInput from '@/components/BaseInput.vue';
import BaseButton from '@/components/BaseButton.vue';
import LoginForm from '@/components/LoginForm.vue';

@Options({
  title: 'Login',
  components: { BaseNavigation, BaseInput, BaseField, BaseButton, LoginForm },
  data() {
    return {
      loading: false,
      success: false,
    }
  },
  methods: {
    login() {
      this.loading = true;
      this.$refs.login.handleSubmit()
      .then(() => {
        this.loading = false;
        this.success = true;
        this.$router.push({ name: 'Dashboard' })
      })
      .catch(() => {
        this.loading = false;
      })
    }
  }
})

export default class Login extends Vue {}
