<template>
  <BaseNavigation>
    <div @click="$router.push({ name: 'Membership' })" class="base-link subdued">Need an account? Register</div>
  </BaseNavigation>
  <div class="login-view">
    <div class="login-container">
      <div class="login-graphic">
        <img src="@/assets/ourforest-icon.svg" alt="OurForest logo">
      </div>
      <div class="login-form-container">
        <h1>Log in</h1>
        <h5>We're glad to have you back! Log in to see what impact has been made since you left.</h5>
        <LoginForm
          @keydown.enter="login()"
          class="login-view__form"
          :loading="loading"
          :success="success"
          ref="login"></LoginForm>
        <BaseButton
          type="submit"
          class="login-view__submit"
          @click="login"
          text="Log me in!"></BaseButton>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import BaseNavigation from '@/components/BaseNavigation.vue';
import BaseField from '@/components/BaseField.vue';
import BaseInput from '@/components/BaseInput.vue';
import BaseButton from '@/components/BaseButton.vue';
import LoginForm from '@/components/LoginForm.vue';

@Options({
  title: 'Login',
  components: { BaseNavigation, BaseInput, BaseField, BaseButton, LoginForm },
  data() {
    return {
      loading: false,
      success: false,
    }
  },
  methods: {
    login() {
      this.loading = true;
      this.$refs.login.handleSubmit()
      .then(() => {
        this.loading = false;
        this.success = true;
        this.$router.push({ name: 'Dashboard' })
      })
      .catch(() => {
        this.loading = false;
      })
    }
  }
})

export default class Login extends Vue {}
</script>

<style lang="scss">
@import '@/design';
.login-view {
  display: flex;
  flex-direction: column;
  max-width: 1080px;
  margin: auto;
  padding: 40px 10px;
  align-items: center;
}
.login-container {
  display: flex;
  width: 100%;
  background: white;
  box-sizing: border-box;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05), 0px 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  position: relative;
  transition: transform ease 0.3s;
  overflow: hidden;
  max-width: 880px;
  min-height: 440px;
  margin-top: 20px;
  flex-wrap: wrap;
}
.login-container .login-graphic {
  max-width: 200px;
  min-width: 280px;
  background: url(~@/assets/forest-background.jpeg);
  background-size: cover;
  background-position-x: center;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  flex: 1;
  box-sizing: border-box;
}
.login-container .login-graphic img {
  height: 80px;
  width: 80px;
}
.login-container .login-form-container {
  text-align: left;
  min-width: 200px;
  flex: 1;
  margin: auto;
  padding: 32px;
  h1 { margin: 0 0 12px 0; }
  h5 { font-weight: $regular; }
}
.login-view__submit {
  max-width: 320px;
}
.login-view__form {
  margin-top: 24px;
  margin-bottom: 32px;
  .login-form__fields {
    flex-direction: column;
  }
}
@media (max-width: 728px) {
  .login-container .login-graphic {
    max-width: unset;
  }
  .login-container .login-form-container {
    padding: 16px;
  }
}
</style>
